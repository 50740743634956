<template>
  <div class="log-box">
    <div class="log-list">
      <van-cell-group>
        <van-cell title="跟进日志列表">
          <template #value>
            <div @click="showLog = true">
              <div v-if="!state.showLogTime">选择时间<van-icon name="arrow-down" /></div>
              <div v-else>{{ state.showLogTime }}</div>
            </div>
          </template>
        </van-cell>
      </van-cell-group>
      <div class="float-sticky">
        <van-button @click="to_addReport" icon="plus" type="primary" />
      </div>
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model:loading="loading" :finished="finished" finished-text="没有更多了" @load="get_list">
          <div v-if="state.logList.length > 0">
            <van-collapse v-model="activeNames">
              <div v-for="(item, index) in state.logList" :key="item.id">
                <van-collapse-item :name="index">
                  <template #title>
                    <div>
                      <span style="color: rgba(0, 0, 0, 0.5); padding-right: 6px"> {{ item.created_at.substring(0, 16) }} </span>
                      <van-tag :type="item.state === 0 ? 'warning' : 'primary'"> {{ item.state === 0 ? '待批复' : '已批复' }}</van-tag>
                    </div>
                  </template>
                  <div>
                    <div style="color: #383838">{{ item.content }}</div>
                    <div class="preview-img" v-if="item.pics" style="margin: 10px 0">
                      <PreviewImage :imglist="item.pics" :imgWidth="state.imgWidth" :imgHeight="state.imgHeight"></PreviewImage>
                    </div>
                    <div v-else></div>
                    <div v-if="item.replies.length > 0">
                      <div class="read">
                        <div v-for="(e, i) in item.replies" :key="e.id">
                          <div class="row between">
                            <span style="color: #2a82e4">{{ e.staff?.name }} 批复</span>
                            <span>{{ e.created_at }}</span>
                          </div>
                          <div class="content">
                            {{ e.content || '批复内容已删除' }}
                            {{ i }}
                            {{ item.replies.length }}
                          </div>
                          <van-divider v-if="i !== item.replies.length - 1" />
                        </div>
                      </div>
                    </div>
                  </div>
                </van-collapse-item>
              </div>
            </van-collapse>
          </div>
          <van-empty v-else description="暂无数据" />
        </van-list>
      </van-pull-refresh>
    </div>

    <van-calendar v-model:show="showLog" type="range" :min-date="state.minDate" @confirm="onConfirmLog" />
  </div>
</template>

<script setup>
import dayjs from 'dayjs'

import { onActivated, reactive, ref } from 'vue'
import { getFollowLogList } from '@/apis/projectDetail'
import { useRouter } from 'vue-router'

const props = defineProps({ project_id: Number })
const router = useRouter()

const state = reactive({
  logList: [],
  showLogTime: '', // 选择的时间范围
  page: 1,
  imgWidth: 80,
  imgHeight: 80,
  minDate: new Date(dayjs().subtract(2, 'year').format('YYYY-MM-DD'))
})

const activeNames = ref([0])
const list = ref([])
const showLog = ref(false)
const loading = ref(false)
const finished = ref(false)
const refreshing = ref(false)

// onMounted(() => {
//   get_list()
// })
onActivated(() => {
  if (state.logList.length !== 0) {
    state.logList = []
    onRefresh()
  }
})
const to_addReport = () => {
  let detail = JSON.parse(localStorage.getItem('PROJECT_DETAIL'))
  router.push('progress-report/addLog?project_id=' + props?.project_id + '&project_name=' + detail.name)
}
const onConfirmLog = values => {
  const [start, end] = values
  showLog.value = false
  state.showLogTime = `${dayjs(start).format('YYYY/MM/DD')}~${dayjs(end).format('YYYY/MM/DD')}`
  state.sdate = dayjs(start).format('YYYY-MM-DD')
  state.edate = dayjs(end).format('YYYY-MM-DD')
  state.logList = []
  onRefresh()
}

const get_list = () => {
  let data = {
    project_id: props?.project_id || 39,
    page: state.page,
    page_size: 10,
    sdate: state.sdate,
    edate: state.edate
  }
  getFollowLogList(data).then(res => {
    if (res.code === 200) {
      if (refreshing.value) {
        state.logList = []
        refreshing.value = false
      }
      state.page += 1
      state.logList = finished.value ? res.data.data : state.logList.concat(res.data.data)
      loading.value = false
      if (state.page > res.data.last_page) {
        finished.value = true //已全部请求完毕
      }
    }
  })
}

const onRefresh = () => {
  // 清空列表数据
  finished.value = false
  state.page = 1
  // 重新加载数据
  // 将 loading 设置为 true，表示处于加载状态
  loading.value = true
  get_list()
}
</script>

<style scoped lang="scss">
.log-box {
  .log-list {
    .read {
      padding: 30px;
      border-radius: 4px;
      background: #f0f7ff;
      color: #a6a6a6;
      .row {
        display: flex;
        flex-direction: row;
      }

      .between {
        justify-content: space-between;
      }
      .content {
        padding-top: 10px;
      }
    }
    .float-sticky {
      z-index: 99;
      position: fixed;
      bottom: 120px;
      right: 40px;
      .van-button {
        width: 100px;
        height: 100px;
        border-radius: 50%;
      }
    }
    .van-cell-group {
      .van-cell {
        background-color: #f0f2f4;
        .van-cell__title {
          color: #000000;
          font-weight: 800;
        }
      }
    }

    .van-collapse {
      .van-collapse-item {
        padding: 0 20px 20px 20px;
      }
    }
  }
}
</style>
